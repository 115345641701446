.ARStatusText {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 0.65rem;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: white;
  width: 80px;
}

.ARStatusTextTooltip {
  font-family: "Montserrat", monospace;
}