.AddButtonRow {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 0;
  margin-right: 0;

  &_space-between{
    justify-content: space-between;
  }

  &__add-button {
    width: 100px;

    @media (max-width: 575px) {
      width: 100%;
      margin-bottom: 10px;
    }
  }
}