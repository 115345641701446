.Incidents-card {
  .table-responsive {
    overflow: visible;

    @media (max-width: 545px) {
      min-height: 280px;
      overflow-x: auto;
      overflow-y: visible;
    }
  }

  .incidents-table {
    .incidents-table__cell {
      vertical-align: middle;

      &_text-center {
        text-align: center;
      }
    }

    &__over-table-row {
      margin: 0 0 10px 0;
    }
  }
}