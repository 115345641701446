@import "./../../styles/templates";

.Members-card {
  .card-body {
    display: flex;
    flex-direction: column;
  }

  .table-responsive {
    overflow-x: auto;
    overflow-y: visible;
    order: 99;
  }

  .c-datatable-items-per-page {
    @media (max-width: 575px) {
      justify-content: flex-start;

      label {
        margin-bottom: 5px;
      }
    }
  }

  .c-datatable-filter {
    flex-wrap: nowrap;

    input {
      width: 200px;
    }

    @media (max-width: 575px) {
      margin-bottom: 5px;
      flex-wrap: wrap;

      input {
        width: 90%;
      }

      label {
        margin-bottom: 5px;
      }
    }
  }

  .Members-card__add-button-row {
    @media (max-width: 575px) {
      order: 1;
    }
  }

  .members-table {
    @extend %members-table;
  }

  .user-info-content {
    @extend %user-info-content;
  }
}