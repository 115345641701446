@import "../../styles/variables";

$Spinner-primary-color: $info;
$Spinner-secondary-color: $info-25;
$Spinner-background-color: rgba(255,255,255,1);

.Spinner-container {
  &_fullscreen {
    position: fixed;
    z-index: 1000;
    background-color: $Spinner-background-color;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.Spinner,
.Spinner:after {
  border-radius: 50%;
  width: 8em;
  height: 8em;
}
.Spinner {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid $Spinner-primary-color;
  border-right: 1.1em solid $Spinner-primary-color;
  border-bottom: 1.1em solid $Spinner-primary-color;
  border-left: 1.1em solid $Spinner-secondary-color;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: Spinner-animation 1.1s infinite linear;
  animation: Spinner-animation 1.1s infinite linear;
}
@-webkit-keyframes Spinner-animation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes Spinner-animation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
