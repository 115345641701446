@import "../../styles/variables";

.RecoveryPassword {
  .RecoveryPassword__info-text {
    color: $gray-700;
  }

  .RecoveryPassword__input-group {
    margin-bottom: 16px;
  }

  .RecoveryPassword__submit-button {
    width: 100%;
  }
}