.dropdown_no-caret {
  .dropdown-toggle:after, .dropdown-toggle:before {
    content: none;
  }
}

// Overwriting of Core UI "dropdown-menu" class
.dropdown-menu {
  z-index: 950;
  margin: 0 !important;
}

.invalid-feedback_show {
  display: block !important;
}

.warning-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: $warning;
}

.delete-circle {
  cursor: pointer;
  fill: lighten($danger, 15%) !important;
  flex-shrink: 0;

  &:hover {
    fill: $danger !important;
  }
}

.add-circle {
  cursor: pointer;
  transform: rotate(45deg);
  fill: lighten($success, 15%) !important;
  flex-shrink: 0;

  &:hover {
    fill: $success !important;
  }
}

.form-control_right-border-radius,
.field_right-border-radius {
  border-top-right-radius: $border-radius !important;
  border-bottom-right-radius: $border-radius !important;
}

.card-column-buttons {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;

  > .btn-link {
    padding-right: 0;
    padding-left: 0;
  }
}

.card-column-buttons__button {
  width: max-content;
  min-width: 100px;

  @media (max-width: 480px) {
    width: 100%;
  }
}

.form-control-button {
  width: fit-content;
  min-width: 100px;
  margin-right: 15px;

  @media (max-width: 480px) {
    width: 100%;
    margin-bottom: 10px;
    margin-right: 0;
  }
}

.form-control-button_mr0 {
  width: fit-content;
  min-width: 100px;

  @media (max-width: 480px) {
    width: 100%;
    margin-bottom: 10px;
  }
}

.form-label-col {
  padding-top: 8px;
}

.form-label {
  margin-bottom: 0;

  @media (max-width: 767px) {
    margin-bottom: 8px;
    padding-top: 0;
  }
}

.form-textarea-label {
  @media (min-width: 768px) {
    margin-top: 7px;
    margin-bottom: 0;
  }
}

.textarea-size {
  resize: none;
  width: 100% !important;
  height: 150px !important;
}

.card-header-text {
  margin: 0;
}

.add-button-row {
  margin-bottom: 10px;
}

.collapse-button {
  @media (max-width: 480px) {
    width: 100%;
  }
}

.collapse-button_active {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom: 0;

  @media (max-width: 480px) {
    width: 100%;
  }
}

.block-text-overflow {
  display: block !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.word-break {
  word-break: break-all;
  white-space: normal;
  max-width: 200px;
}

.table-pagination {
  order: 100;

  &_hidden {
    display: none;
  }
}

.validationFeedback {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;

  &_danger {
    color: $danger;
  }

  &_warning {
    color: $warning;
  }
}

.checkbox {
  margin-left: 20px;

  &__label {
    margin-bottom: 0;
    vertical-align: middle;
  }
}

.centered-fit-content {
  width: fit-content;

  margin-left: auto;
  margin-right: auto;
}

.no-top-border {
  border-top: 0;
}

.no-bottom-border {
  border-bottom: 0;
}

.no-side-borders {
  border-left: 0;
  border-right: 0;
}

.cursor_default {
  cursor: default;
}