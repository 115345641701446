.ARFlag {
  .flag-svg {
    width: 20px;
    height: 15px;
  }

  .ru-svg {
    background-image: url("ru.svg");
  }

  .en-svg {
    background-image: url("en.svg");
  }
}