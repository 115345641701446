.RulesInfo__content {
  display: flex;
  justify-content: space-between;

  @media all and (max-width: 480px) {
    flex-wrap: wrap;
  }
}

.RulesInfo__action-buttons {
  height: 40px;
  min-width: 135px;
}

.RulesInfo__edit-button {
  min-width: 70px;
}

.RulesInfo__content-text {
  margin: 0 15px 0 0;

  @media (max-width: 480px) {
    margin: 0 0 15px 0;
  }
}

.RulesInfo__action-buttons-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 480px) {
    align-items: flex-start;
  }
}

.RulesInfo__rule-switcher {
  @media (max-width: 480px) {
    margin: 20px 0 30px 0;
  }
}