.ProfileCard {
  .Avatar {
    .Avatar__image {
      object-fit: cover;
    }

    .Avatar__upload {
      display: flex;
      flex-direction: column;

      margin-top: 8px;
    }
  }
}