.Notification-container {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 1001;
}

.Notification {
  -webkit-box-shadow: 0 0 8px 0 rgba(34, 60, 80, 0.15);
  -moz-box-shadow: 0 0 8px 0 rgba(34, 60, 80, 0.15);
  box-shadow: 0 0 8px 0 rgba(34, 60, 80, 0.15);
}