.SubscriptionInfo__switcher-group {
  margin-bottom: 10px;
}

.SubscriptionInfo__payment-info {
  text-align: left;
}

.SubscriptionInfo__collapse-card {
  margin-bottom: 10px;
}