//Members table templates
%members-table {
  .members-table__cell {
    vertical-align: middle !important;
  }

  .members-table__link {
    a {
      text-decoration: none !important;
      color: #3c4b64 !important;
    }

    a:hover {
      color: black !important;
    }
  }

  .members-table__phone {
    white-space: nowrap;
    hyphens: none;
  }
}

%user-info-content {
  display: flex;
  align-items: center;

  .user-info-content__text {
    margin-left: 10px;
  }
}