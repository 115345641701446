.HeaderLanguageDropdown__dropdown-menu {
  min-width: 86px;
  padding: 0;
  transform: translate(-13px, -1px);

  .HeaderLanguageDropdown__dropdown-item {
    min-width: auto;
    @media all and (max-width: 400px) {
      > .HeaderLanguageDropdown__long-language {
        display: inline;
      }
    }
  }

  .HeaderLanguageDropdown__long-language {
    display: inline;
    @media all and (max-width: 480px) {
      display: none;
    }
  }

  .HeaderLanguageDropdown__short-language {
    display: inline;
  }
}
