.ARAvatarRow {
  .ARAvatarRow__column {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .user-info-content {
    max-width: 100%;

    @media (max-width: 480px) {
      margin: auto;
      text-align: center;
    }

    .user-info-content__image {
      min-height: 60px;
      max-width: 60px;
      margin-right: 15px;
    }

    .user-info-content__text {
      display: inline-block;
      margin: 0;
      vertical-align: middle;
      max-width: 100%;
    }
  }

  .upload-avatar-content {
    margin-left: auto;
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;

    @media (max-width: 480px) {
      width: 100%;
      margin-top: 15px;
    }

    .upload-avatar-content__image-info {
      display: flex;
      align-items: center;
      margin-right: 10px;

      @media (max-width: 480px) {
        margin: auto;
      }
    }

    .upload-avatar-content__delete-button {
      padding: 5px;
    }

    .upload-avatar-content__upload-button {
      width: 180px;

      @media (max-width: 480px) {
        width: 100%;
      }
    }

    .upload-avatar-content__input {
      display: none;
    }
  }
}