.SignUpUser__input-group {
  margin-bottom: 16px;
}

.SignUp__subdomain-input {
  text-align: right;
}

.SignUp__subdomain-field {
  margin-bottom: 36px;
}