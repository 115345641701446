.Directories {
  .Directories__add-button {
    margin-left: auto;

    @media(max-width: 452px) {
      width: 100%;
      height: 35px
    }
  }

  .Directories__directories-header {
    display: flex;
    flex-direction: row;

    @media(max-width: 452px) {
      flex-direction: column;
    }
  }

  .Directories__list-group {
    > li {
      border: none;
    }

    > li:not(:last-child) {
      border-bottom: 2px solid #ebedef;
    }
  }

  .Directory-Card__disable-enable-button {
    width: 110px;
  }

  .Directory-Card__info {
    display: flex;
    flex-direction: row;
    padding: 0;

    span {
      width: 50%;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      span {
        width: auto;
      }
    }
  }
}