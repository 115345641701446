.CollapseCard {
  border: 0;

  &__header-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    width: 100%;
  }

  &__header-text {
    margin-bottom: 0;
    display: inline-block;
  }

  &__body {
    border: 1px solid #39f;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top: 0;
  }
}