@mixin picker-style($width: 300px, $max-height: 200px) {
  position: absolute;
  padding: 0;
  margin: 10px 0 10px -1px;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.35);
  background-color: white;
  width: $width;
  max-height: $max-height;
  overflow-y: scroll;
  border-radius: 0.25rem;
  z-index: 10;
}

@mixin hide-scrollbar {
  -ms-overflow-style: none;  /* IE 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar { /* chrome based */
    width: 0px;  /* ширина scrollbar'a */
    background: transparent;  /* опционально */
  }
}

@mixin disable-select {
  -ms-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}