.TelegramAuthContent {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid;
  border-color: #d8dbe0;

  &__login-widget {
    height: 40px;
  }

  .TelegramAuthContent-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.75rem 1.25rem;
  }

  .TelegramAuthContent-header {
    text-align: center;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    &__text {
      margin: 0;
    }
  }

  .tooltip {
    &__icon {
      margin: 0 0 0 5px;
    }
  }
}