.TableCard {
  .TableCard__header {
    justify-content: space-between;
  }

  .TableCard-table {
    .TableCard-table__cell {
      vertical-align: middle;
    }
  }
}