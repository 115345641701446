// All values for the variables have been taken from Core UI variables with same names
$warning: #f9b115;
$danger: #e55353;
$success: #2eb85c;

$info: #39f;
$info-25: #c0e6ff;

$gray-200: #d8dbe0;
$gray-700: #768192;

$secondary-100: #ebedef;

$border-radius: .25rem;