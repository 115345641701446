.Checks-card {
  .table-responsive {
    overflow-y: visible;

    @media (max-width: 545px) {
      min-height: 280px;
      overflow-x: auto;
      overflow-y: visible;
    }
  }

  .c-datatable-filter {
    flex-wrap: nowrap;

    input {
      width: 200px;
    }

    label {
      justify-content: left;
      width: 60px;
    }

    @media (max-width: 575px) {
      margin-bottom: 5px;
      flex-wrap: wrap;

      input {
        width: 90%;
      }

      label {
        margin-bottom: 5px;
      }
    }
  }

  .checks-table {
    &__cell {
      vertical-align: middle;

      &_text-center {
        text-align: center;
      }

      &_hidden {
        display: none;
      }
    }
  }

  .c-datatable-items-per-page {
    @media (max-width: 575px) {
      justify-content: flex-start;

      label {
        margin-bottom: 5px;
      }
    }
  }
}