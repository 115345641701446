.LoginSub__subdomain-field {
  text-align: right;
}

.LoginSub__non-existent-alert__text {
  margin: 0;
  line-height: 2rem;
}

.LoginSub__non-existent-alert__register {
  margin: 0 5px;
}