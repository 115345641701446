@import "../../../../../../styles/mixins";
@import "../../../../../../styles/variables";

@mixin time_selection {
  cursor: pointer;

  &_selected {
    background-color: $info;
    color: white;
  }

  &:hover {
    background-color: lighten($info, 20%);
  }
}

.TimePicker {
  @include picker-style(auto, auto);
  display: inline-block;
  padding: 5px;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  top: 32px;
  transition: opacity 0.15s ease-in-out;

  &_show {
    visibility: visible;
    opacity: 1;
  }

  &_placement-right {
    right: 0;
  }

  &_placement-left {
    left: 0;
  }
}

.TimePicker__container {
  display: inline-flex;
}

.TimePicker__reset {
  text-align: center;
  font-size: 1.2rem;
  margin: 5px;
  border-radius: $border-radius;

  @include time_selection;

}

.TimePicker__hours, .TimePicker__minutes {
  @include hide-scrollbar;
  font-size: 1.2rem;
  max-height: 250px;
  overflow: auto;
  outline: none;
  list-style: none;
  margin: 0 2px;
  padding: 0;
}

.TimePicker__hour-container, .TimePicker__minute-container {
  padding: 3px;
  height: 42px;
}

.TimePicker__hour, .TimePicker__minute {
  @include disable-select;
  padding: 0 10px;
  border-radius: $border-radius;

  @include time_selection;
}