.Subscribe-card {
  .Subscribe-card-body {
    &__discount-group {
      margin-bottom: 15px;
    }

    &__plans-container {
      @media (min-width: 575px) {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .plan-card {
    @media (min-width: 575px) {
      width: 49%;
    }
  }
}