.CheckHistory-card {
  &__check-history-table-wrapper {
    margin: 0 0 40px 0;
  }

  .check-creation-row {
    &__text {
      margin: 0;
    }
  }

  .rule-creation-row {
    &__text {
      margin-bottom: 40px;
    }
  }

  .history-table {
    &__cell_word-breakable {
      word-break: break-all;
    }
  }
}