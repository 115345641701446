@import "../../../../../styles/mixins";
@import "../../../../../styles/variables";

.input-group > .react-tel-input:not(:last-child) {
  .form-control {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.input-group > .react-tel-input:not(:first-child) {
  .flag-dropdown {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.react-tel-input {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 200px;

  @media all and (max-width: 320px) {
    min-width: 100%;
  }

  &:disabled {
    cursor: not-allowed;
  }

  * {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
  }

  .hide {
    display: none;
  }

  .v-hide {
    visibility: hidden;
  }

  .form-control {
    display: block;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    background-clip: padding-box;
    border-radius: 0 $border-radius $border-radius 0;
    color: #768192;

    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: calc(100% - 40px);
    min-width: 0;
    position: relative;
    letter-spacing: .01rem;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    outline: none;
    left: 40px;

    &:focus {
      z-index: 2;
    }
  }

  .flag-dropdown {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    white-space: nowrap;
    border-radius: $border-radius 0 0 $border-radius;
    color: #768192;
    background-color: #ebedef;
    border: 1px solid #d8dbe0;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0;

    &:hover, &:focus {
      cursor: pointer;
    }

    &.open {
      color: #768192;
      border-color: #958bef;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(50, 31, 219, 0.25);
    }
  }

  input[disabled] {
    & + .flag-dropdown {
      &:hover {
        cursor: default;

        .selected-flag {
          background-color: transparent;
        }
      }
    }
  }

  .selected-flag {
    outline: none;
    position: relative;
    width: 38px;
    height: 100%;
    padding: 0 0 0 8px;
    border-radius: 3px 0 0 3px;

    .flag {
      position: absolute;
      top: 50%;
      margin-top: -5px;
    }

    .arrow {
      position: relative;
      top: 50%;
      margin-top: -2px;
      left: 20px;
      width: 0;
      height: 0;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-top: 4px solid #555;

      &.up {
        border-top: none;
        border-bottom: 4px solid #555;
      }
    }
  }

  .country-list {
    outline: none;
    list-style: none;
    @include picker-style(304px);

    .flag {
      display: inline-block;
    }

    .divider {
      padding-bottom: 5px;
      margin-bottom: 5px;
      border-bottom: 1px solid #ccc;
    }

    .country {
      padding: 7px 9px;

      .dial-code {
        color: #6b6b6b;
      }

      &:hover {
        background-color: #f1f1f1;
      }

      &.highlight {
        background-color: #f1f1f1;
      }
    }

    .flag {
      margin-right: 7px;
      margin-top: 2px;
    }

    .country-name {
      margin-right: 6px;
    }

    .search {
      position: sticky;
      top: 0;
      background-color: #fff;
      padding: 10px 0 6px 10px;
    }

    .search-emoji {
      font-size: 15px;
    }

    .search-box {
      border: 1px solid #cacaca;
      border-radius: 3px;
      font-size: 15px;
      line-height: 15px;
      margin-left: 6px;
      padding: 3px 8px 5px;
      outline: none;
    }

    .no-entries-message {
      padding: 7px 10px 11px;
      opacity: .7;
    }
  }

  .invalid-number-message {
    position: absolute;
    z-index: 1;
    font-size: 13px;
    left: 46px;
    top: -8px;
    background: #fff;
    padding: 0 2px;
    color: #de0000;
  }

  .special-label {
    display: none;
    position: absolute;
    z-index: 1;
    font-size: 13px;
    left: 46px;
    top: -8px;
    background: #fff;
    padding: 0 2px;
    white-space: nowrap;
  }
}