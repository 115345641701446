@import "../../../styles/variables";

.RulesCard__rule {
  padding: 30px 0;
  border-top: 2px solid $secondary-100;
}

.RulesCard-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__text {
    margin: 0 10px 0 0;
  }

  &__add-button {
    width: fit-content;
    min-width: 100px;
  }

  @media (max-width: 480px) {
    flex-wrap: wrap;

    &__add-button {
      width: 100%;
      margin: 10px 0 0 0;
    }
  }
}