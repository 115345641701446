.CheckDetails-card {
  &__buttons {
    display: flex;
    flex-direction: row;

    div:first-child {
      display: inline-block;
    }

    button {
      width: fit-content;
      min-width: 100px;
    }

    @media (max-width: 610px) {
      flex-direction: column;
      button:not(:last-child) {
        margin-right: 0;
        margin-bottom: 10px;
      }

      button {
        width: 100%;
      }
    }
  }

  &__change-project-icon {
    margin-left: 8px;
    cursor: pointer;
  }
}
