.FreePlan-card {
  text-align: center;

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #c4c9d0;
  }

  &__list {
    text-align: left;
    list-style: none;
    padding-inline-start: 0;
  }
}