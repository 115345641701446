.header {
  align-items: center;
}

.header-nav {
  padding-right: 16px;

  @media (max-width: 480px) {
    margin-left: auto;

    .c-header-nav-items {
      @media (max-width: 575px) {
        margin-right: 0 !important;
      }
    }
  }

  &__production_mode {
    margin-left: auto;
  }
}

.header-nav__subscribe-button {
  margin-left: auto;
  margin-right: 10px;

  @media (max-width: 480px) {
    width: 100%;
    margin: 0;
    padding: 0 16px;
    order: 1;
  }
}

.subheader {
  @media (max-width: 480px) {
    order: 99;
  }
}