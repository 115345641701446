.ProjectSelector {
  display: flex;
  align-items: center;

  &__label {
    width: 68px;
    margin: 0;
  }

  &__select {
    width: 200px;
  }

  @media (max-width: 575px) {
    width: 100%;
    margin: 0 0 10px 0;
    flex-direction: column;
    align-items: flex-start;

    &__label {
      margin: 0 0 5px 0;
    }

    &__select {
      width: 100%;
    }
  }
}