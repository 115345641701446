@import "../../../../../styles/mixins";
@import "../../../../../styles/variables";

.ARControlList {
  display: flex;
  height: auto;
  min-height: calc(1.5em + 0.75rem + 12px);
  flex-wrap: wrap;

  @media all and (max-width: 500px) {
    min-width: 100% !important;
  }
}

.ARControlList__prepend-text {
  @media all and (max-width: 500px) {
    border-radius: $border-radius $border-radius 0 0 !important;
  }
}

.ARControlList__elements {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 3px;
  padding: 2px 5px;
  background-color: lighten($info-25, 10%);
  border-radius: $border-radius;

  @media all and (max-width: 500px) {
    min-width: 100%;
  }
}

.ARControlList__element-text {
  word-break: normal;
}

.ARControlList__empty-text {
  display: flex;
  align-items: center;
  color: $gray-200;
  @include disable-select;
}